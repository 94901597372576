/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const dictionary = {
    'linksName': {
        'gitlab': 'Gitlab',
        'linkedin': 'LinkedIn',
        'mail': 'Mail',
        'upwork': 'Upwork',
        'stackOverFlow': 'Stack Overflow',
        'twitter': 'Twitter'
    },
    'linksURL': {
        'mail': 'mailto:francois@francoisdevilez.eu',
        'linkedin': 'https://www.linkedin.com/in/francois-devilez-a88158155/',
        'gitlab': 'https://gitlab.com/fdevilez'
    },
    'LinksLogoURL': {
        'gitlab': 'https://seeklogo.com/images/G/gitlab-logo-757620E430-seeklogo.com.png',
        'linkedin': 'https://seeklogo.com/images/L/linkedin-in-icon-logo-2E34704F04-seeklogo.com.png',
        'mail': 'https://seeklogo.com/images/Y/yandex-mail-logo-0EB68B8C5F-seeklogo.com.png'
    },
    'roles': {
        'user': 'ROLE_USER',
        'admin': 'ROLE_ADMIN'
    },
    'sections': {
        'aboutMe': {
            'welcomeText': 'Welcome on francoisdevilez.eu',
            'welcomeText2': 'This is my personal website/portfolio.',
            'cvDownloadButton': 'Download my CV'
        },
        'technologies': {
            'learningTechnologies': 'Technologies I am currently learning',
            'masteredTechnologies': 'Technologies I have experience with',
            'adminButton': {
                'commons': {
                    'title': 'Common actions',
                    'exportTechnologies': 'Export technologies in file',
                    'importTechnologies': 'Import technologies file',
                    'uploadIcon': 'Upload new icon'
                },
                'masteredTechnologies': {
                    'refresh': 'Refresh technologies',
                    'add': 'Add technology',
                    'delete': 'Delete technology'
                },
                'learningTechnologies': {
                    'refresh': 'Refresh technologies',
                    'add': 'Add technology',
                    'delete': 'Delete technology'
                }
            }
        },
        'projects': {
            'title': 'Personal projects'
        },
        'experiences': {
            'title': 'Professional experiences'
        }
    }
};
